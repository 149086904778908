/* IMPORTS ---------------------*/
@import "../partials/base";
/*-----------------------------*/

body{ margin: 0; padding: 0; }
img, embed, object, video{
    /*width: 100%*/
}

div > p { margin-top: 0; padding-top: 0; }
p+ul { margin-top: 0;}
p{ margin: 5px 0; padding:0; }

/* Headings */
h1 {
   font-weight: 400;
   font-size: 32px;
   line-height: 40px;
   margin: 20px 0 20px 0;
}

h2 {
   font-weight: 400;
   font-size: 26px;
   margin: 20px 0 60px 0;
   line-height: 30px;
}

h3 {
   margin: 20px 0 10px 0;
   font-weight: 600;
   font-size: 16px;
   line-height: 26px;
}

h4 {
   font-weight: 600;
   font-size: 14px;
}


/* Pager */        
.list-view .pager{
    text-align: left;
    margin-top: 25px;
}
.pager a:visited{
    color: #222;
}
.pager a{
    border:none;
    background: #fbc405;
    font-family: "Oswald", "Times New Roman", serif;
    font-weight: normal; 
    text-transform: uppercase;
    color: #222;
    border-radius: 14px;
    padding: 7px 15px;
    text-decoration: none;
    letter-spacing: 1px;
    text-shadow: none;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;               
}
.pager a:hover{
    color: #ffd957;
    background: #b68e05;
    box-shadow: inset 0 2px 2px #7e6304;
    text-shadow: #7e6304 0.1em 0.1em 0.2em;           
}
.pager .hidden{
    display: none;
}
.pager li{
    display: inline;
}

.pager li.page.selected a{
    color: #222;
    background: #f1f1f1;
    box-shadow: inset 0 2px 2px #737373;
    text-shadow: 0 2px 2px #c1c1c1;
    cursor: default;                
} 

/* ShortCode */

.tooltip {
  display:none;
  position:absolute;
  border-radius:5px;
  box-shadow:2px 2px 3px rgba(0,0,0,.2);
  background-color:#fff;
  border:1px solid #F7F7F7;
  text-shadow:-1px -1px 0 #F4F4F4;
  color:#666;
  font-size: 14px;
  font-style: italic;
  padding:5px;
  width: 250px;
}

.ac_one_half, .ac_one_third, .ac_two_third, .ac_one_fourth , .ac_three_fourth { float:left; margin-right:4%; position:relative; }
.ac_one_half { width: 48%; }
.ac_one_third { width: 30.66%; }
.ac_two_third { width:65.32%; }
.ac_one_fourth { width:22%; }
.ac_three_fourth { width:74%; }
.ac_last { margin-right: 0px !important; clear:right; }

.ac-box { margin: 0px 0px 10px; -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.1); -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1); box-shadow: 0 1px 1px rgba(0,0,0,0.1); font-size: 16px; position: relative; }
	.ac-box-content { text-shadow: 1px 1px 1px #ffffff; padding: 27px 43px 17px 66px; min-height: 35px; }
		.ac-box-content strong { color: #000000; font-weight: bold; }

.ac-alert:before, .ac-success:before, .ac-warning:before, .ac-info:before, .ac-shadow:before { content: ''; position: absolute; top: 32px; left: 28px; }

.ac-warning { border: 1px solid #e2e2ba; }
	.ac-warning:before { width: 22px; height: 20px; }
	.ac-warning .ac-box-content { color: #8C8650; background: #f9f9dc; border: 1px solid #fbfbef; -moz-box-shadow: inset 0 0 45px #efefc0; -webkit-box-shadow: inset 0 0 45px #efefc0; box-shadow: inset 0 0 45px #efefc0; }
		.ac-warning .ac-box-content h1, .ac-warning .ac-box-content h2, .ac-warning .ac-box-content h3, .ac-warning .ac-box-content h4, .ac-warning .ac-box-content h5, .ac-warning .ac-box-content h6 { color: #8C8650; }

.ac-alert { border: 1px solid #e0b1b1; }
	.ac-alert:before { width: 17px; height: 17px; }
	.ac-alert .ac-box-content { color: #ad7676; background: #ffd9d5; border: 1px solid #fceeec; -moz-box-shadow: inset 0 0 45px #f0bfba; -webkit-box-shadow: inset 0 0 45px #f0bfba; box-shadow: inset 0 0 45px #f0bfba; }
		.ac-alert .ac-box-content h1, .ac-alert .ac-box-content h2, .ac-alert .ac-box-content h3, .ac-alert .ac-box-content h4, .ac-alert .ac-box-content h5, .ac-alert .ac-box-content h6 { color: #ad7676; }

.ac-success { border: 1px solid #b9dbb6; }
	.ac-success:before { width: 19px; height: 14px; }
	.ac-success .ac-box-content { color: #5E855B; background: #dff7dd; border: 1px solid #f1f9f0; -moz-box-shadow: inset 0 0 45px #cbe5c9; -webkit-box-shadow: inset 0 0 45px #cbe5c9; box-shadow: inset 0 0 45px #cbe5c9; }
		.ac-success .ac-box-content h1, .ac-success .ac-box-content h2, .ac-success .ac-box-content h3, .ac-success .ac-box-content h4, .ac-success .ac-box-content h5, .ac-success .ac-box-content h6 { color: #5E855B; }

.ac-info { border: 1px solid #b3d8d3; }
	.ac-info:before { width: 19px; height: 15px; }
	.ac-info .ac-box-content { color: #3A87AD; background: #D9EDF7; border: 1px solid #f1fafb; -moz-box-shadow: inset 0 0 45px #c6edf0; -webkit-box-shadow: inset 0 0 45px #c6edf0; box-shadow: inset 0 0 45px #c6edf0; }
		.ac-info .ac-box-content h1, .ac-info .ac-box-content h2, .ac-info .ac-box-content h3, .ac-info .ac-box-content h4, .ac-info .ac-box-content h5, .ac-info .ac-box-content h6 { color: #638F93; }

.ac-shadow { border: 1px solid #ddd; }
	.ac-shadow .ac-box-content { color: #737373; background: #fafafa; border: 1px solid #fafafa; -moz-box-shadow: inset 0 0 45px #e7e7e7; -webkit-box-shadow: inset 0 0 45px #e7e7e7; box-shadow: inset 0 0 45px #e7e7e7; }
		.ac-shadow .ac-box-content h1, .ac-shadow .ac-box-content h2, .ac-shadow .ac-box-content h3, .ac-shadow .ac-box-content h4, .ac-shadow .ac-box-content h5, .ac-shadow .ac-box-content h6 { color: #737373; }
                
.ac-box-icon-container{
    font-size: 20px;
    left: 30px;
    position: absolute;
    top: 29px;
}

.ac-warning .ac-box-icon-container{
    color: #B2B24B;
}

.ac-alert .ac-box-icon-container, ul.ac-list i.icon-remove{
    color: #A74062;
}

.ac-success .ac-box-icon-container, ul.ac-list i.icon-ok{
    color: green;
}

.ac-info .ac-box-icon-container{
    color: #4C8CC7;
}

ul.ac-list{
    list-style: none;
}

blockquote {
    font-family: Georgia, serif;
    font-size: 16px;
    font-style: italic;
    padding: 0.25em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;
}

blockquote:before {
    display: block;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
}

.errorMessage{
    font-size: 12px;
    color: red;
}