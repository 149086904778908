@include susy-breakpoint(320px, 6) {
    .container{
        .resp-menu-top {
            display: block;
        }
        ul.menu{
            display: none;
        }
    }
}
@include susy-breakpoint(480px, 6) {
    .container{
    }

}
@include susy-breakpoint(768px, 12) {
    .container{
    }
}
@include susy-breakpoint(1024px, 12) {
    .container{
        .resp-menu-top {
            display: none;
        }
        ul.menu{
            display: block;
        }
    }

}
@include susy-breakpoint(1140px, 24) {
    .container{
    }

}
@include susy-breakpoint(1280px, 24) {

}
/*@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}*/


