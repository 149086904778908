
// ---------------------------------------------------------------------------
// IMPORTS
@import "../bower_components/susy/sass/susy";
// ---------------------------------------------------------------------------


//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/

@import 'mixins/sprite';
@import 'mixins/fontface';



$total-columns: 12; // 12 columns

// the basics
$columns: 12;
$column-width: 4em;
$gutter-width: 1em;
$grid-padding: 1em;

// advanced
$container-width: false;
$container-style: magic;
$from-direction: left;
$omega-float: right;

// media-query fallbacks
$breakpoint-media-output: true;
$breakpoint-ie-output: true;
$breakpoint-raw-output: false;

$susy: (
  // the basics
  columns: $total-columns,
  gutters: $gutter-width / $column-width,
  column-width: $column-width,

  // advanced
  container: $container-width,
  math: if($container-style == magic, fluid, $container-style),
  flow: if($from-direction == right, rtl, ltr),
  last-flow: if($omega-float == $from-direction, from, to),
);
  



/* Settings */

$main-padding: 10px;

$base-text-color: #000;

$primary-bg-color: red;
$primary-text-color: green;
$primary-hover-bg-color: green;
$primary-hover-text-color: red;

$secondary-bg-color: blue;
$secondary-text-color: purple;
$secondary-hover-bg-color: purple;
$secondary-hover-text-color: blue;

$link-color: yellow;

$menu-font: 'Open Sans', sans-serif;

$headins-font: 'Open Sans', sans-serif;

$text-font: 'Open Sans', sans-serif;




