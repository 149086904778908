// ---------------------------------------------------------------------------
// Imports
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin-ext');
@import "partials/base";
@include border-box-sizing;

//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import 'partials/header';
@import 'partials/menu';
@import 'partials/sidebar';
@import 'partials/main';
@import 'partials/footer';
@import "modules/all";

.container { 
    @include container; max-width: 750px; 
    position: relative;
}
.menu-container, .slider-container, .main-container, .footer-container{@include span(full); position:relative;}



/* HORIZONTAL LINES HELP */
/*@mixin susy-grid-background {
  @include grid-background(12, $column-width, $gutter-width, $base-line-height, 0em, $force-fluid: true);
}*/

//---Demo

/*.container {
    @include susy-grid-background;
    @include at-breakpoint(47.9em $break-m) { @include susy-grid-background; }
    @include at-breakpoint($break-l) { @include susy-grid-background; }
}*/