/**
 * CSS styles for forms generated by yiic.
 *
 * The styles can be applied to the following form structure:
 *
 * <div class="form">
 *     <div class="row">
 *         <label for="inputid">xyz</label>
 *         <input name="inputid" id="inputid" type="text" />
 *         <p class="hint">hint text</p>
 *     </div>
 *     <div class="row">
 *         <label for="inputid">xyz</label>
 *         <input name="inputid" id="inputid" type="text" />
 *         <p class="hint">hint text</p>
 *     </div>
 *     <div class="row buttons">
 *         <label for="inputid">xyz</label>
 *         <input name="inputid" id="inputid" type="text" />
 *         <p class="hint">hint text</p>
 *     </div>
 * </div>
 *
 * The above code will render the labels and input fields in separate lines.
 * In order to render them in the same line, please use the "wide" form as follows,
 *
 * <div class="wide form">
 *     ......
 * </div>
 *
 * @author Qiang Xue <qiang.xue@gmail.com>
 * @link http://www.yiiframework.com/
 * @copyright Copyright &copy; 2008-2010 Yii Software LLC
 * @license http://www.yiiframework.com/license/
 */

/* IMPORTS ---------------------*/
@import "../partials/base";
/*-----------------------------*/

div.form
{
}

div.form input,
div.form textarea,
div.form select
{
	margin: 0.2em 0 0.5em 0;
}

div.form input[type=submit]{
    width: 110px;
}

div.form fieldset
{
	border: 1px solid #DDD;
	padding: 10px;
	margin: 0 0 10px 0;
    -moz-border-radius:7px;
}

div.form label
{
	font-weight: bold;
	font-size: 0.9em;
	display: block;
}

div.form .row
{
	margin: 5px 0;
}

div.form .hint
{
	margin: 0;
	padding: 0;
	color: #999;
}

div.form .note
{
	font-style: italic;
}

div.form span.required
{
	color: red
}

div.form div.error label,
div.form label.error,
div.form span.error
{
	color: #C00;
}

div.form div.error input,
div.form div.error textarea,
div.form div.error select,
div.form input.error,
div.form textarea.error,
div.form select.error
{
	background: #FEE;
	border-color: #C00;
}

div.form div.success input,
div.form div.success textarea,
div.form div.success select,
div.form input.success,
div.form textarea.success,
div.form select.success
{
	background: #E6EFC2;
	border-color: #C6D880;
}


div.form .errorSummary
{
	border: 2px solid #C00;
	padding: 7px 7px 12px 7px;
	margin: 0 0 20px 0;
	background: #FEE;
	font-size: 0.9em;
}

div.form .errorMessage
{
	color: red;
	font-size: 0.9em;
}

div.form .errorSummary p
{
	margin: 0;
	padding: 5px;
}

div.form .errorSummary ul
{
	margin: 0;
	padding: 0 0 0 20px;
}

div.wide.form label
{
	float: left;
/*	margin-right: 10px;*/
	position: relative;
	text-align: left;
/*	width: 100px;*/
}

div.wide.form .row
{
	clear: left;
}

div.wide.form .buttons, div.wide.form .hint, div.wide.form .errorMessage
{
	clear: left;
/*	padding-left: 110px;*/
}
