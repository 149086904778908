/* IMPORTS ---------------------*/
@import 'base';
/*-----------------------------*/

.main {
    font-family:'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
}

.form-htxt { margin-bottom: 60px; border-bottom: 1px solid #ddd; padding-bottom: 10px; }

.form-divcontainer { margin-bottom: 60px; }

.v-error{ color: red; }

.form-txtarea{ 
    font-size: 12px;
    background: #fff;
    width: 595px;
    border: 1px solid #ccc; 
}

.formtable{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 60px;
    
    thead tr { border-top: 1px solid #ddd; }
    
    thead td { vertical-align: top; padding: 5px; padding-left: 20px; }
    
    thead, .formtable-a {
        text-align: center;
    }
    
    .formtable-a { padding-left: 20px; }
    
    .formtable-radio { margin-top: 5px; }
    
    tbody tr {
        border-top: 1px solid #ddd;
    }
    
    tbody td {
        padding: 5px;
    }
    
    .formtable-q{
        width: 220px;
    }
}

.form-final{
    margin: 40px 0;
    text-align: center;
}

.formSend {
    background: #ddd;
    padding: 10px;
    margin-bottom: 60px;
    display: inline-block;
    color: #444;
    text-decoration: none;
}
