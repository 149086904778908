/* IMPORTS ---------------------*/
@import 'base';
/*-----------------------------*/

/*---------------------------------
	MENU LAYOUT
	DO NOT EDIT This Section (unless you know what you are doing)
-----------------------------------*/
.menu{margin:0;padding:0;line-height:100%; 
font-size:0; /* Kill white space gap between LI elements */
position:relative;z-index:1000;}

	.menu:after{clear:both;content:' ';display:block;font-size:0;line-height:0;visibility:hidden;width:0;height:0}
	.menu li{margin:0;padding:0;list-style-type:none;display:inline-block;*display:inline;position:relative;zoom:1;line-height:inherit;
	top:0;left:0;font-size:16px; /* fixed font-size to replace font-size:0 in parent .menu 1em/16px default */}
		.menu li a{margin:0;padding:0;display:block;display:inline;display:inline-block;position:relative;zoom:1;line-height:100%;top:0;left:0;}


/*--------------Sub Menus-------------------*/
	/*.menu li:hover > ul{display:block;}*/
	.menu ul{margin:0;padding:0;position: absolute;top:100%;left:0;display:none;min-width:150px;max-width:150%;*width:150px;}
	.menu ul li{display:block;width:100%;}
	.menu ul li a{display:block;}
		.menu ul ul{top:0;left:100%;}
		
/*--------------Dividers-------------------*/		
	.menu ul li.divider{border-top:1px solid #ccc;}
	.menu ul li.divider a{border-top:1px solid #fff;}


/*--------------Right---------------------*/
	.menu li.right{float:right;}

	
/*--------------Arrows-------------------*/
	.menu li.has-menu a{padding-right:25px;}
	.menu li.has-menu span.arrow{border-style:solid;border-width:5px;
	display:block;position:absolute;top:50%;right:5px;font-size:0;line-height:0;height:0;width:0;}
	.menu li li.has-menu span.arrow{margin-top:-4px;}

/*--------------Vertical Menu Layout-------------------*/
.menu.vertical{}
	.menu.vertical li{display:block;}
	.menu.vertical li a{display:block;}
	.menu.vertical ul{top:0;left:100%;}
	.menu.vertical li.has-menu span.arrow{margin-top:-4px;}

/*--------------Vertical Right Menu Layout-------------------*/
.menu.vertical.right{text-align:left;}
	.menu.vertical.right ul{top:0;right:100%;left:auto;}
	.menu.vertical.right li a{padding-left:25px;padding-right:20px;}
	.menu.vertical.right li.has-menu span.arrow{right:auto;left:5px;margin-top:-4px;}
	
	
/*---------------------------------
	MENU STYLES
	EDIT BELOW THIS LINE TO CUSTOMIZE
-----------------------------------*/
.menu{
    width: 100%;
    text-align: right;
    text-decoration: none;
    font-family: $menu-font;
    font-weight: normal; 
    text-transform: uppercase;   
    z-index:600;
}

.menu li{}

    .menu li a{
    padding:30px;
    text-decoration:none;
    /*font-size:0.9em;*/
    color: $primary-text-color;
    }

    .menu li.current>a,
    .menu li.current>a:hover,
    .menu li.current.hover>a{
    background: $primary-hover-bg-color;
    color:$primary-hover-text-color;
    cursor: default;
    }

    .menu li a:hover,
    .menu li.hover>a{
    background:$primary-hover-bg-color;
    color:$primary-hover-text-color;
    }

/* sub menus */
.menu ul{
background: #fff;
border-bottom:1px solid #ccc;
border-left:1px solid #ccc;
border-right:1px solid #ccc;
box-shadow: 0 2px 2px #c1c1c1;
top: 77px;

}

.menu ul li{}
.menu ul li a{
font-size: 13px;
color: $secondary-text-color;
padding: 20px 10px;}
.menu ul li.current a:hover{
background: $secondary-hover-bg-color;
}
.menu ul li.current a,
.menu ul li.hover a{
background: $secondary-hover-bg-color;
color: $secondary-hover-text-color;
}

    /* sub-sub menus */
    .menu ul ul{}
    .menu ul ul li{}
    .menu ul ul li a{}

/* arrows */
/* arrow down */  .menu li.has-menu span.arrow{border-color-top:#ccc;border-color:#ccc transparent transparent transparent;}
/* arrow left */  .menu li li.has-menu span.arrow, .menu.vertical li.has-menu span.arrow
                                {border-color-left:#ccc;border-color:transparent transparent transparent #ccc;}
/* arrow right */ .menu.vertical.right li.has-menu span.arrow{border-color-right:#ccc;border-color:transparent #ccc transparent transparent;}

/* dividers */ 	  .menu ul li.divider{border-top:1px solid #ccc;}
                                .menu ul li.divider a{border-top:1px solid #fff;}

.resp-menu-top, .resp-menu-top ul{
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.resp-menu-top a {
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}
.resp-menu-top li div{
    display:none;
    margin-top: 3px;
    background: #fff;
    position: absolute;
    margin: 0 $grid-padding 0 $grid-padding;
    left: 0;
    right: 0;
    z-index: 600;
    /*border-right: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    border-top: 1px solid #E5E5E5;*/
    
    box-shadow: 0 4px 3px #4b4b4b;
    
        li.resp-menu-toptext{
        //background: url("../img/header_logo.png") 0 17px no-repeat;
    }
    li.resp-menu-toptext > a{
        cursor: pointer;
    }
    li.resp-menu-toptext > a span{
        padding-left: 30px;
        //background: url("../img/menu_icon.png") left center no-repeat;
    }
    li.menu_main_resp a,
    li.menu_main_resp a:visited{
        color: #000;
    }
    li.menu_main_resp a:hover{
        background: $secondary-hover-bg-color;
        color: $secondary-hover-text-color;
    }
    li.menu_main_resp{
        border-top: 1px solid #E5E5E5;
    }
    li.menu_main_resp li{
        border-top: 1px solid #E5E5E5;
    }
    li.menu_main_resp li a{
        font-weight: normal;
    }    
    li.menu_main_resp a{
        padding: 10px 0px;
        margin: 0;
    }   
}

/*Hamburger Menu*/
        
    li.resp-menu-toptext > a { 
        position: absolute;
        right: 10px;
        top: 0;
        width: 36px;
        height: 61px;
        padding-top: 35px;
    }

    li.resp-menu-toptext > a span, li.resp-menu-toptext > a span:before, li.resp-menu-toptext > a span:after {
        cursor: pointer;
        border-radius: 1px;
        height: 5px;
        width: 35px;
        background: #222;
        position: absolute;
        display: block;
        content: '';
      }
    li.resp-menu-toptext > a span:before {
      top: -10px;
    }
    li.resp-menu-toptext > a span:after {
      bottom: -10px; 
    }
